import React, { useState, useEffect } from 'react';
import { useLanguage } from '../context/LanguageContext';
import './TranslationList.css';
import linkLogo from '../assets/images/link.ico'; // Assuming this is the icon for the link

const MAX_TITLE_LENGTH = 80; // Maximum title length

const TranslationListStatistics = ({
  items,
  selectedYear,
  selectedSortOption,
  selectedSortOrder,
  showAliasPTFilter,
  showAliasESFilter,
}) => {
  const { language } = useLanguage(); // Assuming this returns 'pt' or 'es'

  useEffect(() => {
    items.forEach(element => {
      console.log(`element: ${JSON.stringify(element)}`);
    });
  }, [items, selectedYear, selectedSortOption, selectedSortOrder, showAliasPTFilter, showAliasESFilter]);

  const truncateTitle = (title) => {
    if (title && title.length > MAX_TITLE_LENGTH) {
      return title.slice(0, MAX_TITLE_LENGTH) + '...';
    }
    return title;
  };

  const getFieldValue = (item, fieldName) => {
    // Access the field for both original and the current language
    const originalField = `${fieldName}Original`;
    const localizedField = `${fieldName}-${language.toLowerCase()}`;
    // console.log (`originalField: ${originalField} - localizedField: ${localizedField}`);
    return `${item[originalField] ?? '0'} / ${item[localizedField] ?? '0'}`;
  };

  return (
    <div>
      <table className="translation-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>
              <div>Original</div>
              <div>Blog</div>
            </th>
            <th>
              <div>Translated</div>
              <div>Blog</div>
            </th>
            <th>
              <div>Page Views</div>
              <div>(Original / Translated)</div>
            </th>
            <th>
              <div>Page Views (Excl. Reloads)</div>
              <div>(Original / Translated)</div>
            </th>
            <th>
              <div>Unique Visitors</div>
              <div>(Original / Translated)</div>
            </th>
            <th>
              <div>Quality Visits</div>
              <div>(Original / Translated)</div>
            </th>
            <th>
              <div>Clicks to Console</div>
              <div>(Original / Translated)</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td>{truncateTitle(item.title)}</td>
              <td>
                  {' '}
                  {/*Original Blog link*/}
                  <a href={item.postUrl} target="_blank" rel="noopener noreferrer">
                    <img src={linkLogo} alt="Link" className="small-icon" />
                  </a>
                </td>
                <td>
                  {/* Translated blog link */}
                  {item[`translatedUrl-${language === 'PT' ? 'pt' : 'es'}`] ? (
                    <a href={item[`translatedUrl-${language === 'PT' ? 'pt' : 'es'}`]} target="_blank" rel="noopener noreferrer">
                      <img src={linkLogo} alt="Link" className="small-icon" />
                    </a>
                  ) : (
                    'N/A'
                  )}
                </td>
              <td>{getFieldValue(item, 'pageViews')}</td>
              <td>{getFieldValue(item, 'pageViewsExcludeReloads')}</td>
              <td>{getFieldValue(item, 'uniqueVisitor')}</td>
              <td>{getFieldValue(item, 'qualityVisits')}</td>
              <td>{getFieldValue(item, 'clicksToConsole')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TranslationListStatistics;
