const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

// Helper function for making API requests
async function makeApiRequest(path, method, body) {
    const url = `${API_BASE_URL}${path}`;
    const headers = { 'Content-Type': 'application/json' };
    // console.log(url);
    
    const response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify(body)
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
}

// Update the owner of a translation
export async function updateOwner(postUrl, alias, language) {
    const path = '/translationUpdateOwner';
    const method = 'POST';
    const body = { postUrl, alias, language };

    return makeApiRequest(path, method, body);
}

// Download files related to a translation
export async function downloadFiles(postUrl, language, fileType) {
    const path = '/translationGetFiles';
    const method = 'POST';
    const body = { postUrl, language, fileType };

    const data = await makeApiRequest(path, method, body);
    return data.preSignedUrl; // Assuming the API returns a pre-signed URL for file download
}

// Update the job status of a translation
export async function updateJobStatus(postUrl, status, language) {
    const path = '/translationUpdateJobStatus';
    const method = 'POST';
    const body = { postUrl, status, language };

    return makeApiRequest(path, method, body);
}

export async function fetchTranslationData () {
    const path = '/blogGetAllPosts';
    const method = 'GET';
    return makeApiRequest(path, method);
};

export async function fetchBlogPostConfigurationData () {
    const path = '/blogPostsConfigurations';
    const method = 'GET';
    return makeApiRequest(path, method);
};