import React, { useState, useEffect } from 'react';
import FiltersContainer from '../components/FiltersContainer';
import TranslationListStatistics from '../components/TranslationListStatistics';
import HeaderContent from '../components/HeaderContent';
import { fetchTranslationData } from '../services/translationApi';
import './HomePage.css';

const StatisticsPagePage = () => {
  // State variables
  const [translationItems, setTranslationItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editableRowAliasPT, setEditableRowAliasPT] = useState(null);
  const [editableRowAliasES, setEditableRowAliasES] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedSortOption, setSelectedSortOption] = useState('DatePublished');
  const [selectedSortOrder, setSelectedSortOrder] = useState('descending');
  const [selectedYear, setSelectedYear] = useState('All'); 
  const [noData, setNoData] = useState(false); 
  const [showAliasPTFilter, setShowAliasPTFilter] = useState(false);
  const [showAliasESFilter, setShowAliasESFilter] = useState(false);

  useEffect(() => {
    // Load filter state from localStorage when component mounts
    const savedFilters = localStorage.getItem('filterState');
    if (savedFilters) {
      const filterState = JSON.parse(savedFilters);
      setSelectedCategory(filterState.selectedCategory);
      setSelectedSortOption(filterState.selectedSortOption);
      setSelectedSortOrder(filterState.selectedSortOrder);
      setSelectedYear(filterState.selectedYear);
      setShowAliasPTFilter(filterState.showAliasPTFilter);
      setShowAliasESFilter(filterState.showAliasESFilter);
      // console.log('Filter state loaded from localStorage:', JSON.stringify(filterState));
    } else {
      // console.log('No filter state found in localStorage.');
      saveLocalStorageFilters({ selectedCategory: selectedCategory, selectedSortOption: selectedSortOption, selectedSortOrder: selectedSortOrder, 
        selectedYear: selectedYear, showAliasPTFilter: showAliasPTFilter, showAliasESFilter: showAliasESFilter });
      // console.log ("userEffect from HomePage: " +  JSON.stringify (selectedCategory, selectedSortOption, selectedSortOrder, selectedYear, showAliasPTFilter, showAliasESFilter));
    }
    // Fetch data from your API endpoint
    fetchTranslationData()
      .then((data) => {
        console.log('********** Data:', JSON.stringify(data));
        if (data && data.length > 0) {
          setTranslationItems(data);
          // console.log('Data fetched successfully:', JSON.stringify(data));
        } else {
          console.error('No data returned from the API.');
          setNoData(true); // Set noData state to true if no data is returned
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setNoData(true); // Set noData state to true if there is an error
      })
      .finally(() => setLoading(false));
  }, [selectedCategory, selectedSortOption, selectedSortOrder, selectedYear, showAliasESFilter, showAliasPTFilter]);

  const saveLocalStorageFilters = (filters) => {
    // console.log ("saveLocalStorageFilters:" + JSON.stringify(filters));
    localStorage.setItem('filterState', JSON.stringify(filters));
  };

  /**
   * Handles the change of selected category filter.
   *
   * @param {string} category - The selected category.
   */
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    saveLocalStorageFilters({ selectedCategory: category, selectedSortOption, selectedSortOrder, selectedYear, showAliasPTFilter, showAliasESFilter });
  };

  /**
   * Handles the change of selected sort option.
   *
   * @param {string} sortOption - The selected sort option.
   */
  const handleSortChange = (sortOption) => {
    setSelectedSortOption(sortOption);
    // console.log ("handleSortChange: " +  JSON.stringify (selectedCategory, selectedSortOption, selectedSortOrder, selectedYear, showAliasPTFilter, showAliasESFilter));

    saveLocalStorageFilters({ selectedCategory, selectedSortOption: sortOption, selectedSortOrder, selectedYear, showAliasPTFilter, showAliasESFilter });
  };

  /**
   * Handles the change of selected sort order.
   *
   * @param {string} sortOrder - The selected sort order.
   */
  const handleSortOrderChange = (sortOrder) => {
    setSelectedSortOrder(sortOrder);
    // console.log ("handleSortOrderChange: " +  JSON.stringify (selectedCategory, selectedSortOption, selectedSortOrder, selectedYear, showAliasPTFilter, showAliasESFilter));

    saveLocalStorageFilters({ selectedCategory, selectedSortOption, selectedSortOrder: sortOrder, selectedYear, showAliasPTFilter, showAliasESFilter });
  };

    /**
   * Handles the change of selected sort order.
   *
   * @param {string} sortOrder - The selected sort order.
   */
    const handleYearChange = (year) => {
      setSelectedYear(year);
      saveLocalStorageFilters({ selectedCategory, selectedSortOption, selectedSortOrder, selectedYear: year, showAliasPTFilter, showAliasESFilter });
  
    };

  /**
   * Resets all filters to their default values.
   */
  const handleResetFilters = () => {
    setSelectedCategory('All');
    setSelectedSortOption('DatePublished');
    setSelectedSortOrder('descending');
    setSelectedYear('All'); // Reset selectedYear to default
    setShowAliasPTFilter(false);
    setSelectedSortOrder(false);
    saveLocalStorageFilters({ selectedCategory: "All", selectedSortOption: "DatePublished", selectedSortOrder: "descending", selectedYear: "All", showAliasPTFilter: false, showAliasESFilter: false });
    console.log ("handleResetFilters: " +  JSON.stringify (selectedCategory, selectedSortOption, selectedSortOrder, selectedYear, showAliasPTFilter, showAliasESFilter));

  };

  /**
   * Toggles the display of Alias PT filter.
   */
  const handleToggleAliasPTFilter = () => {
    setShowAliasPTFilter(!showAliasPTFilter);
    saveLocalStorageFilters({ selectedCategory, selectedSortOption, selectedSortOrder, selectedYear, showAliasPTFilter: showAliasPTFilter, showAliasESFilter });
  };

  /**
   * Toggles the display of Alias ES filter.
   */
  const handleToggleAliasESFilter = () => {
    setShowAliasESFilter(!showAliasESFilter);
    saveLocalStorageFilters({ selectedCategory, selectedSortOption, selectedSortOrder, selectedYear, showAliasPTFilter, showAliasESFilter: showAliasESFilter });
  };

  const updateTranslationItems = (updatedItems) => {
    setTranslationItems(updatedItems); // Assuming setTranslationItems is defined in this component
  };

  // Apply filters and sorting to the translation items
  const filteredItems = translationItems
    .filter((item) => selectedCategory === 'All' || item.category === selectedCategory)
    // .filter((item) => selectedFinalStatus === 'All' || item.finalTranslateStatus === (selectedFinalStatus === 'true'))
    .filter((item) => {
      if (!showAliasPTFilter) {
        return true;
      } else {
        if (item['alias-pt'] == null || !item['alias-pt'] === ' ') return true;
      }
      return false;
    })
    .filter((item) => {
      if (!showAliasESFilter) {
        return true;
      } else {
        if (item['alias-es'] == null || !item['alias-es'] === ' ') return true;
      }
      return false;
    })
    .filter((item) => {
      if (selectedYear === 'All') {
        return true;
      }
      const itemYear = new Date(item.datePublished).getFullYear();
      return itemYear.toString() === selectedYear;
    })
    .sort((a, b) => {
      switch (selectedSortOption) {
        case 'DatePublished':
          const dateA = new Date(a.datePublished);
          const dateB = new Date(b.datePublished);
          return selectedSortOrder === 'ascending' ? dateA - dateB : dateB - dateA;
        case 'Category':
          return selectedSortOrder === 'ascending' ? a.category.localeCompare(b.category) : b.category.localeCompare(a.category);
        case 'ReviewedBy':
          const aliasA = a.aliasPT || '';
          const aliasB = b.aliasPT || '';
          return selectedSortOrder === 'ascending' ? aliasA.localeCompare(aliasB) : aliasB.localeCompare(aliasA);
        case 'ReviewedByES':
          const aliasESA = a.aliasES || '';
          const aliasESB = b.aliasES || '';
          return selectedSortOrder === 'ascending' ? aliasESA.localeCompare(aliasESB) : aliasESB.localeCompare(aliasESA);
        default:
          return 0;
      }
    });

  return (
    <>
      <HeaderContent />
      <FiltersContainer
        selectedCategory={selectedCategory}
        selectedSortOption={selectedSortOption}
        selectedSortOrder={selectedSortOrder}
        selectedYear={selectedYear} 
        onCategoryChange={handleCategoryChange}
        onSortChange={handleSortChange}
        onSortOrderChange={handleSortOrderChange}
        onYearChange={(year) => handleYearChange(year)}
        onResetFilters={handleResetFilters}
        onToggleReviewFilterPt={handleToggleAliasPTFilter}
        onToggleReviewFilterEs={handleToggleAliasESFilter}
        showAliasPTFilter={showAliasPTFilter}
        showAliasESFilter={showAliasESFilter}
      />
      <div className="table-container">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {noData ? (
              <p>No data returned from the API.</p>
            ) : (
              <TranslationListStatistics
                items={filteredItems}
                editableRowAliasPT={editableRowAliasPT}
                setEditableRowAliasPT={setEditableRowAliasPT}
                editableRowAliasES={editableRowAliasES}
                setEditableRowAliasES={setEditableRowAliasES}
                updateTranslationItems={updateTranslationItems}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default StatisticsPagePage;
