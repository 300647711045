import React, { useState, useRef, useEffect } from 'react';

import { useLanguage } from '../context/LanguageContext';

import './TranslationList.css';
import linkLogo from '../assets/images/link.ico';
import WorkDoneModal from './WorkDoneModal';

const MAX_TITLE_LENGTH = 80; // Maximum title length

/**
 * Component for displaying a list of translation items.
 *
 * @param {Array} items - The array of translation items.
 * @param {number} editableRowAliasPT - The index of the row with editable PT alias.
 * @param {function} setEditableRowAliasPT - Function to set the index of the row with editable PT alias.
 * @param {number} editableRowAliasES - The index of the row with editable ES alias.
 * @param {function} setEditableRowAliasES - Function to set the index of the row with editable ES alias.
 * @param {string} selectedYear - The selected year for filtering.
 * @param {function} setEditableRowAliasES - Function to set the index of the row with editable ES alias.
 * @param {string} selectedCategory - The selected category for filtering.
 * @param {string} selectedFinalStatus - The selected final status for filtering.
 * @param {string} selectedSortOption - The selected sort option for sorting.
 * @param {string} selectedSortOrder - The selected sort order for sorting.
 * @param {boolean} showAliasPTFilter - Flag to show the PT alias filter.
 * @param {boolean} showAliasESFilter - Flag to show the ES alias filter.
 * @param {function} updateTranslationItems - Function to update translation items.
 * @returns {JSX.Element} TranslationList component JSX.
 */
const TranslationList = ({
  items,
  setEditableRowAliasPT,
  selectedYear,
  setEditableRowAliasES,
  selectedCategory,
  selectedFinalStatus,
  selectedSortOption,
  selectedSortOrder,
  showAliasPTFilter,
  showAliasESFilter,
  updateTranslationItems
}) => {
  const { language } = useLanguage();

  const [editableTranslatedRow, setEditableTranslatedRow] = useState(null);
  const [editableReviewedRow, setEditableReviewedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [translatedUrl, setTranslatedUrl] = useState(null);
  const [workDoneData, setWorkDoneData] = useState(null);

  useRef(() => {
    const filterState = JSON.parse(localStorage.getItem('filterState'));
    if (filterState) {
      // console.log('filterState from TranslationList: ' + JSON.stringify(filterState));
      selectedCategory = filterState.selectedCategory;
      selectedFinalStatus = filterState.selectedFinalStatus;
      selectedSortOption = filterState.selectedSortOption;
      selectedSortOrder = filterState.selectedSortOrder;
      selectedYear = filterState.selectedYear;
      showAliasPTFilter = filterState.showAliasPTFilter;
      showAliasESFilter = filterState.showAliasESFilter;
    }
  }, [selectedCategory, selectedFinalStatus, selectedSortOption, selectedSortOrder, selectedYear, showAliasPTFilter, showAliasESFilter]);

  useEffect(() => {
    // UseEffect logic should be placed here
  }, [selectedCategory, selectedFinalStatus, selectedSortOption, selectedSortOrder, selectedYear, showAliasPTFilter, showAliasESFilter]);

  /**
   * Handles assigning the translator to the current user.
   *
   * @param {number} index - The index of the translation item.
   * @param {string} language - The language of the translation (e.g., 'pt' or 'es').
   */
  const handleAssignTranslatorToMe = (index, language) => {
    const item = items[index];
    const finalTranslateStatusField = `finalTranslateStatus-${language}`;

    if (item[finalTranslateStatusField]) {
      alert("Cannot assign when 'Final Translation Status' is true.");
      return;
    }

    setEditableTranslatedRow(index);

    const aliasInputId = `aliasTranslator-${language}-${index}`;
    const aliasInput = document.getElementById(aliasInputId);

    if (!aliasInput) {
      console.error(`Element with ID ${aliasInputId} not found.`);
      return;
    }

    const alias = aliasInput.value;

    fetch(process.env.REACT_APP_API_BASE_URL + 'translationUpdateOwner', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        postUrl: item.postUrl,
        aliasTranslator: alias,
        language
      })
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const updatedItems = items.map((item, itemIndex) => {
          if (itemIndex === index) {
            return { ...item, [`aliasTranslator-${language}`]: alias }; // Update the alias
          }
          return item;
        });
        updateTranslationItems(updatedItems);

        if (language === 'pt') {
          setEditableRowAliasPT(null);
        } else if (language === 'es') {
          setEditableRowAliasES(null);
        }
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error updating alias:', error);
      });
  };

  /**
   * Handles assigning the reviewer to the current user.
   *
   * @param {number} index - The index of the translation item.
   * @param {string} language - The language of the translation (e.g., 'pt' or 'es').
   */
  const handleAssignReviewerToMe = (index, language) => {
    const item = items[index];
    const finalTranslateStatusField = `finalTranslateStatus-${language}`;

    if (item[finalTranslateStatusField]) {
      alert("Cannot assign when 'Final Translation Status' is true.");
      return;
    }

    setEditableReviewedRow(index);

    const aliasInputId = `aliasReviewer-${language}-${index}`;
    const aliasInput = document.getElementById(aliasInputId);

    if (!aliasInput) {
      console.error(`Element with ID ${aliasInputId} not found.`);
      return;
    }

    const alias = aliasInput.value;

    fetch(process.env.REACT_APP_API_BASE_URL + 'translationUpdateOwner', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        postUrl: item.postUrl,
        aliasReviewer: alias,
        language
      })
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const updatedItems = items.map((item, itemIndex) => {
          if (itemIndex === index) {
            return { ...item, [`aliasReviewer-${language}`]: alias }; // Update the alias
          }
          return item;
        });
        updateTranslationItems(updatedItems);

        if (language === 'pt') {
          setEditableRowAliasPT(null);
        } else if (language === 'es') {
          setEditableRowAliasES(null);
        }
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error updating alias:', error);
      });
  };

  /**
   * Handle downloading files for a translation item.
   *
   * @param {Event} event - The event object.
   * @param {number} index - The index of the translation item in the list.
   * @param {string} language - The language of the translation (e.g., 'PT' or 'ES').
   * @param {string} fileType - The type of file to download (e.g., 'approval', 'original', 'translation').
   */
  const handleDownload = (event, index, language, fileType) => {
    const item = items[index];

    if ((fileType === 'original' || fileType === 'translation') && item[`translationStatus-${language}`] !== 'translated') {
      alert(`Cannot download ${fileType} files for item at index ${index} and language ${language}`);
      return;
    }

    // Save current filter states before reload
    saveFilterState({ selectedCategory, selectedFinalStatus, selectedSortOption, selectedSortOrder, selectedYear, showAliasPTFilter, showAliasESFilter });

    fetch(process.env.REACT_APP_API_BASE_URL + 'translationGetFiles', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ postUrl: item.postUrl, language, fileType })
    })
      .then((response) => {
        if (!response || !response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        event.preventDefault();
        window.open(data.signedUrl, '_blank');
      })
      .catch((error) => {
        console.error('Error making API call or downloading file:', error);
        alert('Error downloading file. Please try again.');
      });
  };

  /**
   * Handle submission of the modal form.
   *
   * @param {string} translatedUrl - The translated URL.
   */
  const handleSubmitModal = (translatedUrl) => {
    setTranslatedUrl(translatedUrl);
    console.log('***** Translated URL:', translatedUrl);
    setIsModalOpen(false);
  };

  /**
   * Handle closing the modal.
   */
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const updateWorkDone = async () => {
      if (translatedUrl && workDoneData != null) {
        const item = items[workDoneData];

        try {
          console.log('Updating status for:', item.postUrl);

          const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'translationUpdateJobStatus', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              postUrl: item.postUrl,
              status: 'done',
              language: language.toLowerCase(),
              translatedUrl: translatedUrl
            })
          });

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          setTranslatedUrl(null);
          setWorkDoneData(null);

          window.location.reload();
        } catch (error) {
          alert(`Error calling API: ${error}`);
          console.error('Error making API call:', error);
        }
      }
    };

    updateWorkDone();
  }, [translatedUrl, items, updateTranslationItems, workDoneData, language]);

  /**
   * Handle marking work as done.
   *
   * @param {number} index - The index of the translation item.
   */
  const handleWorkDone = (index) => {
    console.log('handleWorkDone function is called');
    setIsModalOpen(true);
    setWorkDoneData(index);
  };

  /**
   * Truncate the title of a translation item if it exceeds the maximum length.
   *
   * @param {string} title - The title of the translation item.
   * @returns {string} The truncated title.
   */
  const truncateTitle = (title) => {
    if (title && title.length > MAX_TITLE_LENGTH) {
      return title.slice(0, MAX_TITLE_LENGTH) + '...';
    }
    return title;
  };

  /**
   * Save the current filter state to local storage.
   *
   * @param {Object} filterState - The object containing the filter state.
   * @param {string} filterState.selectedCategory - The selected category for filtering.
   * @param {string} filterState.selectedFinalStatus - The selected final status for filtering.
   * @param {string} filterState.selectedSortOption - The selected sort option for sorting.
   * @param {string} filterState.selectedSortOrder - The selected sort order for sorting.
   * @param {string} filterState.selectedYear - The selected year for filtering.
   * @param {boolean} filterState.showAliasPTFilter - Flag to show the PT alias filter.
   * @param {boolean} filterState.showAliasESFilter - Flag to show the ES alias filter.
   */
  const saveFilterState = ({ selectedCategory, selectedFinalStatus, selectedSortOption, selectedSortOrder, selectedYear, showAliasPTFilter, showAliasESFilter }) => {
    const filterState = { selectedCategory, selectedFinalStatus, selectedSortOption, selectedSortOrder, selectedYear, showAliasPTFilter, showAliasESFilter };
    localStorage.setItem('filterState', JSON.stringify(filterState));
  };

  return (
    <>
      <div>
        <table className="translation-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>
                <div>Original</div>
                <div>Blog</div>
              </th>
              <th>
                <div>Translated</div>
                <div>Blog</div>
              </th>
              <th>Category</th>
              <th>Status</th>
              <th>Published</th>
              <th>
                <div>Translator</div>
                <div>(Alias)</div>
              </th>
              <th>
                <div>Reviewer</div>
                <div>(Alias)</div>
              </th>
              <th>
                <div>Assign</div>
                <div>Translator</div>
              </th>
              <th>
                <div>Assign</div>
                <div>Reviewer</div>
              </th>
              <th>Work Done</th>
              <th>Download Files</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>
                  {' '}
                  {/*Title*/}
                  {truncateTitle(item.title)}
                </td>
                <td>
                  {' '}
                  {/*Original Blog link*/}
                  <a href={item.postUrl} target="_blank" rel="noopener noreferrer">
                    <img src={linkLogo} alt="Link" className="small-icon" />
                  </a>
                </td>
                <td>
                  {/* Translated blog link */}
                  {item[`translatedUrl-${language === 'PT' ? 'pt' : 'es'}`] ? (
                    <a href={item[`translatedUrl-${language === 'PT' ? 'pt' : 'es'}`]} target="_blank" rel="noopener noreferrer">
                      <img src={linkLogo} alt="Link" className="small-icon" />
                    </a>
                  ) : (
                    'N/A'
                  )}
                </td>
                <td>
                  {' '}
                  {/*Category*/}
                  {item.category}
                </td>
                <td>
                  {' '}
                  {/*Status*/}
                  {item[`translationStatus-${language === 'PT' ? 'pt' : 'es'}`] || 'Not Started'}
                </td>
                <td>
                  {' '}
                  {/*Data Published*/}
                  {item.datePublished ? item.datePublished.toLowerCase() : ''}
                </td>
                <td>
                  {' '}
                  {/*Translated*/}
                  {language === 'PT' ? (
                    editableTranslatedRow === index ? (
                      <input type="text" id={`aliasTranslator-pt-${index}`} defaultValue={item['aliasTranslator-pt']} />
                    ) : (
                      <span>{item['aliasTranslator-pt']}</span>
                    )
                  ) : editableTranslatedRow === index ? (
                    <input type="text" id={`aliasTranslator-es-${index}`} defaultValue={item['aliasTranslator-es']} />
                  ) : (
                    <span>{item['aliasTranslator-es']}</span>
                  )}
                </td>
                <td>
                  {' '}
                  {/*Reviewed*/}
                  {language === 'PT' ? (
                    editableReviewedRow === index ? (
                      <input type="text" id={`aliasReviewer-pt-${index}`} defaultValue={item['aliasReviewer-pt']} />
                    ) : (
                      <span>{item['aliasReviewer-pt']}</span>
                    )
                  ) : editableReviewedRow === index ? (
                    <input type="text" id={`aliasReviewer-es-${index}`} defaultValue={item['aliasReviewer-es']} />
                  ) : (
                    <span>{item['aliasReviewer-es']}</span>
                  )}
                </td>
                <td>
                  {' '}
                  {/*Assign translation to Me*/}
                  {language === 'PT' ? (
                    editableTranslatedRow === index && !item[`finalTranslateStatus-pt`] && item['translationStatus-pt'] === 'translated' ? (
                      <button onClick={() => handleAssignTranslatorToMe(index, 'pt')}>Update</button>
                    ) : (
                      <button onClick={() => setEditableTranslatedRow(index)} disabled={item[`finalTranslateStatus-pt`] || item['translationStatus-pt'] !== 'translated'}>
                        Assign
                      </button>
                    )
                  ) : editableTranslatedRow === index && !item[`finalTranslateStatus-es`] && item['translationStatus-es'] === 'translated' ? (
                    <button onClick={() => handleAssignTranslatorToMe(index, 'es')}>Update</button>
                  ) : (
                    <button onClick={() => setEditableTranslatedRow(index)} disabled={item[`finalTranslateStatus-es`] || item['translationStatus-es'] !== 'translated'}>
                      Assign
                    </button>
                  )}
                </td>
                <td>
                  {' '}
                  {/*Assign reviewer to Me*/}
                  {language === 'PT' ? (
                    editableReviewedRow === index && !item[`finalTranslateStatus-pt`] && item['translationStatus-pt'] === 'translated' ? (
                      <button onClick={() => handleAssignReviewerToMe(index, 'pt')}>Update</button>
                    ) : (
                      <button onClick={() => setEditableReviewedRow(index)} disabled={item[`finalTranslateStatus-pt`] || item['translationStatus-pt'] !== 'translated'}>
                        Assign
                      </button>
                    )
                  ) : editableReviewedRow === index && !item[`finalTranslateStatus-es`] && item['translationStatus-es'] === 'translated' ? (
                    <button onClick={() => handleAssignReviewerToMe(index, 'es')}>Update</button>
                  ) : (
                    <button onClick={() => setEditableReviewedRow(index)} disabled={item[`finalTranslateStatus-es`] || item['translationStatus-es'] !== 'translated'}>
                      Assign
                    </button>
                  )}
                </td>
                <td>
                  {' '}
                  {/* Work done */}
                  <button
                    onClick={() => handleWorkDone(index)}
                    disabled={item[`finalTranslateStatus-${language === 'PT' ? 'pt' : 'es'}`] || item[`translationStatus-${language === 'PT' ? 'pt' : 'es'}`] !== 'translated'}
                  >
                    Done
                  </button>
                </td>
                <td>
                  {' '}
                  {/*Download files*/}
                  <button
                    onClick={(event) => handleDownload(event, index, language === 'PT' ? 'pt' : 'es', 'approval')}
                    disabled={item[`translationStatus-${language === 'PT' ? 'pt' : 'es'}`] !== 'translated' || !item.approvalUploaded}
                  >
                    Approved
                  </button>
                  <button
                    onClick={(event) => handleDownload(event, index, language === 'PT' ? 'pt' : 'es', 'original')}
                    disabled={item[`translationStatus-${language === 'PT' ? 'pt' : 'es'}`] !== 'translated'}
                  >
                    Original
                  </button>
                  <button
                    onClick={(event) => handleDownload(event, index, language === 'PT' ? 'pt' : 'es', 'translation')}
                    disabled={item[`translationStatus-${language === 'PT' ? 'pt' : 'es'}`] !== 'translated'}
                  >
                    Translated
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <WorkDoneModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleSubmitModal} />
      </div>
    </>
  );
};

export default TranslationList;
