import React, { useState, useEffect } from 'react';
import { fetchBlogPostConfigurationData } from '../services/translationApi';

import HeaderContent from '../components/HeaderContent';

import './ConfigurationsBlogPostCatagories.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const ConfigurationsBlogPostCatagories = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchBlogPostConfigurationData()
      .then((response) => {
        const sortedData = response.result.sort((a, b) => a.category.localeCompare(b.category, 'en', { sensitivity: 'base' }));
        setData(sortedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setData([]);
      });
  }, []);

  const handleRemoveEmail = (email, type, index, category) => {
    const updatedData = [...data];
    updatedData[index][type] = updatedData[index][type].filter((e) => e !== email);
    console.log (`Category: ${category}, type: ${type}, index: ${index}, email: ${email}, action: REMOVE`)
    setData(updatedData);
  };

  const handleAddEmail = (type, index, category) => {
    const newEmail = prompt('Enter new email:');
    if (newEmail && newEmail.endsWith('@amazon.com')) {
      const updatedData = [...data];
      if (!updatedData[index][type]) updatedData[index][type] = [];
      updatedData[index][type].push(newEmail);
      setData(updatedData);
    } else {
      alert('Please enter a valid email ending with @amazon.com');
    }
    console.log (`Category: ${category}, type: ${type}, index: ${index}, email: ${newEmail}, action: ADD`)

  };

//   console.log("Data received from the API: " + JSON.stringify(data));

  return (
    <>
      <HeaderContent />
      <div className="configurations-container">
        {data.length > 0 ? (
          <table className="configurations-table">
            <thead>
              <tr>
                <th>Category</th>
                <th>Blog URL</th>
                <th className="original-approvals">Original Approvals</th>
                <th className="translated-pt">Translated PT</th>
                <th className="translated-es">Translated ES</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.category}</td>
                  <td>
                    <a href={item.blogPostUrl} target="_blank" rel="noopener noreferrer">
                      {item.blogPostUrl}
                    </a>
                  </td>
                  <td className="original-approvals">
                    {item.originalRequesterApproval.map((email, emailIndex) => (
                      <div key={emailIndex} className="email-entry">
                        {email}
                        <button onClick={() => handleRemoveEmail(email, 'originalRequesterApproval', index, item.category)} className="icon-button">
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    ))}
                    <div className="add-button-container">
                      <button onClick={() => handleAddEmail('originalRequesterApproval', index, item.category)}>Add</button>
                    </div>
                  </td>
                  <td className="translated-pt">
                    {item.sendTranslatedBlogFilesPT.map((email, emailIndex) => (
                      <div key={emailIndex} className="email-entry">
                        {email}
                        <button onClick={() => handleRemoveEmail(email, 'sendTranslatedBlogFilesPT', index, item.category)} className="icon-button">
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    ))}
                    <div className="add-button-container">
                      <button onClick={() => handleAddEmail('sendTranslatedBlogFilesPT', index, item.category)}>Add</button>
                    </div>
                  </td>
                  <td className="translated-es">
                    {item.sendTranslatedBlogFilesES.map((email, emailIndex) => (
                      <div key={emailIndex} className="email-entry">
                        {email}
                        <button onClick={() => handleRemoveEmail(email, 'sendTranslatedBlogFilesES', index, item.category)} className="icon-button">
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    ))}
                    <div className="add-button-container">
                      <button onClick={() => handleAddEmail('sendTranslatedBlogFilesES', index, item.category)}>Add</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No data available.</p>
        )}
      </div>
    </>
  );
};

export default ConfigurationsBlogPostCatagories;
