import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import StatisticsPage from './pages/StatisticsPage';
import UploadApprovalPage from './pages/UploadApprovalPage';
import ConfigurationsBlogPostCatagories from './pages/ConfigurationsBlogPostCatagories';
import { LanguageProvider } from './context/LanguageContext';

const App = () => {
  return (
    <LanguageProvider>
      <Router>
        <div className="app-container">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/Statistics" element={<StatisticsPage />} />
            <Route path="/UploadApproval" element={<UploadApprovalPage />} />
            <Route path="/BlogPostConfiguration" element={<ConfigurationsBlogPostCatagories />} />
          </Routes>

          <div className="tab-navigation">
            <Link to="/">
              <button className="nav-button nav-button-home">Home</button>
            </Link>
            <Link to="/Statistics">
              <button className="nav-button nav-button-home">Statistics</button>
            </Link>
            <Link to="/UploadApproval">
              <button className="nav-button nav-button-upload">Upload Approval Page</button>
            </Link>
            <Link to="/BlogPostConfiguration">
              <button className="nav-button nav-button-upload">Configurations</button>
            </Link>
          </div>
        </div>
      </Router>
    </LanguageProvider>
  );
};

export default App;
