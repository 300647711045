import React from 'react';
import { useLanguage } from '../context/LanguageContext'; // Import the useLanguage hook from your context

const InReview = ({ showAliasPTFilter, showAliasESFilter, onToggleAliasPT, onToggleAliasES }) => {
  const { language } = useLanguage(); // Use the selected language from context

  return (
    <div className="in-review-section">
    <label>
        Not in Review:
        {language === 'PT' && (
          <div>
            <input type="checkbox" id="alias-pt" checked={showAliasPTFilter} onChange={onToggleAliasPT} />
          </div>
        )}
        {language === 'ES' && (
          <div>
            <input type="checkbox" id="alias-es" checked={showAliasESFilter} onChange={onToggleAliasES} />
          </div>
        )}
      </label>
    </div>
  );
};

export default InReview;
