import React, { useState, useRef } from 'react';
import HeaderContent from '../components/HeaderContent';
import './UploadApprovalPage.css'; // Ensure you have this CSS file in the correct path
import axios from 'axios';

const UploadApprovalPage = () => {
  const [file, setFile] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const fileInputRef = useRef(null); // Create a ref for the file input element

  /**
   * Handles the selection of a file.
   *
   * @param {Event} event - The file input change event.
   */
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setUploadSuccess(false); // Reset success message when a new file is selected
    // console.log(`handleFileChange - Selected File: ${JSON.stringify(selectedFile)}`);
  };

  /**
   * Gets the pre-signed URL from the API.
   *
   * @param {string} postUrl - The post URL.
   * @param {string} filename - The filename.
   * @returns {Promise<string>} The pre-signed URL.
   */
  async function getSignedUrlFromApi(postUrl, filename) {
    try {
      const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'blogApprovalUpdateFile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ postUrl, filename })
      });
      if (response.ok) {
        const responseBody = await response.json();
        const signedUrl = responseBody.signedUrl;
        // console.log(`getSignedUrlFromApi - postUrl: ${postUrl}, filename: ${filename}, signedUrl: ${signedUrl}`);
        return signedUrl;
      } else {
        throw new Error(`Failed to get signed URL: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error getting signed URL:', error);
      alert(`Error getting signed URL`);

      throw error;
    }
  }

  /**
   * Handles the file upload process.
   */
  const handleFileUpload = async () => {
    if (file) {
      try {
        const postUrlInput = document.getElementById('postUrl');
        if (!postUrlInput) {
          console.error('Post URL input not found');
          return;
        }

        const postUrl = postUrlInput.value;
        if (!postUrl) {
          console.error('Post URL is empty');
          return;
        }

        const signedUrl = await getSignedUrlFromApi(postUrl, file.name);

        // console.log(`file.typeL: ${file.type}`);
        let ret = await axios.put(signedUrl, file, {
          headers: { "Content-Type": file.type },
          onUploadProgress: (e) => {
            // var percentCompleted = Math.round((e.loaded * 100) / e.total);
          },
        });

        if (ret.status === 200) {
          // console.log('File uploaded successfully');
          setUploadSuccess(true);
        }

      } catch (error) {
        console.error('Error during upload:', error);
      }
    } else {
      console.log('No file selected');
    }
  };

  /**
   * Clears the form and resets the selected file.
   */
  const handleClearForm = () => {
    setFile(null); // Clear the selected file
    setUploadSuccess(false); // Reset success message
    const postUrlInput = document.getElementById('postUrl');
    if (postUrlInput) {
      postUrlInput.value = ''; // Clear the input field
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the file input element
    }
  };

  return (
    <div>
      <HeaderContent />
      <div className="upload-approval-page">
        <div className="upload-form">
          <div className="input-group">
            <label htmlFor="postUrl">Post URL</label>
            <input
              id="postUrl" // Set the ID here
              type="text"
              placeholder="Enter URL"
            />
          </div>
          <input type="file" onChange={handleFileChange} ref={fileInputRef} />
          <button onClick={handleFileUpload}>Upload</button>
          <button onClick={handleClearForm}>Clear Form</button>
          {file && <p>Selected File: {file.name}</p>}
          {uploadSuccess && <p className="success-message"><strong style={{ color: 'green' }}>File uploaded successfully!</strong></p>}
        </div>
      </div>
    </div>
  );
};

export default UploadApprovalPage;
