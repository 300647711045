import React from 'react';
import './FiltersContainer.css';
import InReview from './InReview'; // Import the InReview component

/**
 * FiltersContainer component for managing filters and sort options.
 *
 * @param {string} selectedCategory - The selected category filter value.
 * @param {string} selectedFinalStatus - The selected final status filter value.
 * @param {string} selectedSortOption - The selected sort option.
 * @param {string} selectedSortOrder - The selected sort order.
 * @param {function} onCategoryChange - Function to handle category filter change.
 * @param {function} onFinalStatusChange - Function to handle final status filter change.
 * @param {function} onSortChange - Function to handle sort option change.
 * @param {function} onSortOrderChange - Function to handle sort order change.
 * @param {function} onResetFilters - Function to reset all filters.
 * @param {function} onToggleReviewFilterPt - Function to toggle Alias PT filter.
 * @param {function} onToggleReviewFilterEs - Function to toggle Alias ES filter.
 * 
 */
const FiltersContainer = ({
  selectedCategory,
  selectedSortOption,
  selectedSortOrder,
  selectedYear,
  onCategoryChange,
  onSortChange,
  onSortOrderChange,
  onResetFilters,
  onYearChange,
  onToggleReviewFilterPt,
  onToggleReviewFilterEs
}) => {
  return (
    <div className="filters-container">
      <div>
        <label htmlFor="category">Filter by Category: </label>
        <select id="category" onChange={(e) => onCategoryChange(e.target.value)} value={selectedCategory} >
          <option value="All">All</option>
          <option value="Analytics">Analytics</option>
          <option value="ArtificialIntelligence">Artificial Intelligence</option>
          <option value="Containers">Containers</option>
          <option value="BusinessIntelligence">Business Intelligence</option>
          <option value="Database">Database</option>
          <option value="DevOps">DevOps</option>
          <option value="EnterpriseStrategy">Enterprise Strategy</option>
          <option value="Games">Games</option>
          <option value="MachineLearning">Machine Learning</option>
          <option value="Microsoft">Microsoft</option>
          <option value="Migration">Migration</option>
          <option value="Mobile">Mobile</option>
          <option value="Modernization">Modernization</option>
          <option value="Networking">Networking</option>
          <option value="Observability">Observability</option>
          <option value="OpenSource">Open Source</option>
          <option value="SaaS">SaaS</option>
          <option value="Security">Security</option>
          <option value="Serverless">Serverless</option>
          <option value="Sustainability">Sustainability</option>
          <option value="Telecommunications">Telecommunications</option>
          <option value="Training">Training</option>
          <option value="WorkforceDevelopment">Workforce Development</option>
          <option value="VMwareCloud">VMwareCloud</option>
        </select>
      </div>
      <div>
        <label htmlFor="sortOption">Sort by: </label>
        <select id="sortOption" onChange={(e) => onSortChange(e.target.value)} value={selectedSortOption} >
          <option value="DatePublished">Date Published</option>
          <option value="Category">Category</option>
        </select>
        <select id="sortOrder" onChange={(e) => onSortOrderChange(e.target.value)} value={selectedSortOrder} >
          <option value="ascending">Ascending</option>
          <option value="descending">Descending</option>
        </select>
      </div>
      <InReview
        onToggleAliasPT={onToggleReviewFilterPt}
        onToggleAliasES={onToggleReviewFilterEs}
      />
      <div>
        <label htmlFor="year">Filter by Year: </label>
        <select id="year" onChange={(e) => onYearChange(e.target.value)} value={selectedYear} >
          <option value="All">All</option>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
          <option value="2022">2022</option>
          <option value="2021">2021</option>
          <option value="2020">2020</option>
          <option value="2019">2019</option>
          <option value="2018">2018</option>
          <option value="2017">2017</option>
          <option value="2016">2016</option>
          <option value="2015">2015</option>
        </select>
      </div>
      <button id="reset-filters-btn" onClick={onResetFilters}>Reset Filters</button>
    </div>
  );
};

export default FiltersContainer;
