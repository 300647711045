import React, { useState } from 'react';
import './WorkDoneModal.css';

const TranslationModal = ({ isOpen, onClose, onSubmit }) => {
  const [translatedUrl, setTranslatedUrl] = useState('');

  const handleSubmit = () => {
    // Perform validation if needed
    onSubmit(translatedUrl);
    setTranslatedUrl('');
  };

  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Add translated URL:</h2>
        <input 
          type="text" 
          value={translatedUrl} 
          onChange={(e) => setTranslatedUrl(e.target.value)} 
          placeholder="Enter translated URL" 
        />
        <div>
          <button onClick={handleSubmit}>OK</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default TranslationModal;
